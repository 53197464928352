import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';

import {
    cartList,
    title,
    wrapper,
    button,
    summary,
    cartEmpty,
    buttonHolder,
    loader,
} from './cart.module.scss';
import { mainGrid } from '../../style/grid.module.scss';
import { selectCart, selectCartIsLoading } from '../../redux/shop/cart/cart.selectors';

import Main from '../../layouts/main';
import SEO from '../../components/seo';
import { CartListConnected } from '../../components/molecules/cart/cart-list';
import ShopCartSummaryPrice from '../../components/atoms/shop-cart-summary-price/shop-cart-summary-price';
import CartButton from '../../components/atoms/cart-button';
import Button from '../../components/atoms/button';
import Title from '../../components/atoms/title';
import LinkButton from '../../components/atoms/link-button';
import Loader from '../../components/atoms/loader';

const Cart = () => {
    const cart = useSelector(selectCart);
    const isCartLoading = useSelector(selectCartIsLoading);
    const count = cart?.items?.length || 0;

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // little hack because of hydration problems
        // with this effect first render is always the same as when building
        setIsLoading(false);
    }, []);

    return (
        <Main isShopCart={true}>
            <SEO title="Sklep" />
            <div className={`${mainGrid} ${wrapper}`}>
                {isLoading && <Loader className={loader} />}
                {!isLoading && count > 0 && (
                    <>
                        <div className={cartList}>
                            <Title className={title}>Mój koszyk</Title>
                            {isCartLoading ? <Loader /> : <CartListConnected context="cart" />}
                        </div>
                        <div className={summary}>
                            <Title className={title}>Łączna kwota</Title>
                            <ShopCartSummaryPrice isDropdownStyle={false} />
                            <div className={buttonHolder}>
                                <Button className={button} onClick={navigateToCart} color="blank">
                                    Kontynuuj zakupy
                                </Button>
                                <CartButton
                                    onClick={navigateToShop}
                                    transitions={false}
                                    title={'dalej'}
                                    fullSize={true}
                                    className={button}
                                />
                            </div>
                        </div>
                    </>
                )}
                {!isLoading && count === 0 && (
                    <div className={cartEmpty}>
                        <Title className={title}>Twój koszyk jest pusty!</Title>
                        <LinkButton to="/sklep">Idź do sklepu</LinkButton>
                    </div>
                )}
            </div>
        </Main>
    );
};

const navigateToCart = () => {
    navigate('/sklep');
};

const navigateToShop = () => {
    navigate('/zamowienie');
};

export default Cart;
